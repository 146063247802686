































































































































import EventsDisplay from '../components/EventsDisplay.vue'
import Vue from "vue";
import { Watch, Component, Prop } from 'vue-property-decorator'
import { discoverSorts } from '../model/DiscoverSort'
import { mapState } from 'vuex'
import { analytics } from '../firebase'
import * as AppEvents from '@/model/AppEvents'

@Component({
  components: {
    EventsDisplay
  },
  computed: {
    ...mapState([
      'activeTab',
      'discoverFeedTab',
      'discoverEvents',
      'promotions',
      'discoverSort'
    ])
  }
})
export default class Discover extends Vue {
  discoverEvents!: any
  promotions!: any
  discoverFeedTab!: number

  loading = true
  typeToggle = 0

  private get sortOptions() {
    return discoverSorts
  }

  dateDisplay(date: any) {
    var d = new Date(1970, 0, 1); // Epoch
    d.setSeconds(date.seconds);

    return d.toLocaleDateString()
  }

  get activePromos() {
    var active = this.promotions.filter(promo => promo.isActive && !this.isCompleted(promo))

    active = active.filter(n => !this.upcomingPromos.includes(n))

    active.sort(function(a,b){
      return a.endDate - b.endDate;
    });
    
    return active
  }

  get upcomingPromos() {
    var upcoming = this.promotions.filter(promo => new Date(promo.startDate.seconds * 1000) > new Date() && !this.isCompleted(promo) && promo.isActive)

    upcoming.sort(function(a,b){
      return a.startDate - b.startDate;
    });

    return upcoming
  }

  get closedPromos() {
    return this.promotions.filter(promo => promo.isActive && this.isCompleted(promo));
  }

  isCompleted(promo) {
    const now = new Date()
    const endDate = new Date(promo.endDate.seconds * 1000)
    
    return now > endDate
  }

  async loadMore() {
    this.loading = true
    await this.$store.dispatch('loadEvents')
    this.loading = false
  }

  async setSort(val) {
    this.loading = true
    await this.$store.dispatch('sortDiscover', val)
    this.loading = false
  }

  private mounted() {
    if (this.$route.meta.selectedTabIndex) {
      this.typeToggle = this.$route.meta.selectedTabIndex
    }
    else {
      this.typeToggle = this.discoverFeedTab
    }
    
    this.loading = false
  }

  @Watch('typeToggle')
  private async onTypeChange() {
    await this.$store.dispatch('setDiscoverFeedTab', this.typeToggle)

    analytics.logEvent(AppEvents.PROMOS_TAB_SELECTED, {})
  }
}
